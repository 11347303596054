import "../Styles/departments.scss";
import { useEffect, useState } from "react";
import Header from "../components/Utils/header";
import BlogPost from "../components/News/BlogPost";
import Hero from "../components/Home/Hero";
import Footer from "../components/Utils/footer";
import logo from "../assets/images/wb.png";
import { useRef } from "react";
import Pagination from "../components/Utils/Pagination";
import WaveLoading from "../components/Utils/WaveLoading";
import { useLocation } from "react-router-dom";
import Agriculture from "../components/Departments/Agriculture";
import Livestock from "../components/Departments/Livestock";
import Veterinary from "../components/Departments/Veterinary";
import Fisheries from "../components/Departments/Fisheries";
import KATC from "../components/Departments/KATC";

export default function Departments(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [filter, setFilter] = useState("All");
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const srch = useRef();

  const location = useLocation().pathname.split("/")[2];
  console.log(useLocation().pathname.split("/")[2]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    if (filter == "All") {
      setLoading(true);
      fetch(`/api/projects/paginated/${(currentPage - 1) * 12}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data);
          setData(data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/projects/type/${filter}/${(currentPage - 1) * 12}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [currentPage, refresh, filter]);

  function search(q) {
    setLoading(true);
    fetch(`/api/projects/search/${q}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="departments">
      <div className="headings">
        <Header />
      </div>
      <div className="department">
          {location === "agriculture" ? <Agriculture /> :
            location === "livestock" ? <Livestock /> :
            location === "veterinary" ? <Veterinary /> :
                location === "fisheries" ? <Fisheries /> : <KATC />}
      </div>
      {/* <Hero /> */}
      <Footer />
    </div>
  );
}

const Category = (props) => {
  return (
    <div
      onClick={() => {
        props.setFilter(props.txt);
        props.setCurrentPage(1);
      }}
      className="category"
    >
      <p>{props.txt}</p>
    </div>
  );
};

const Project = (props) => {
  return (
    <div className="stk">
      <div className="tp">
        <img src={"/api/" + props.item.Image.replaceAll("\\", "/")} alt="" />
        <h3>{props.item.Title}</h3>
      </div>
      <br />
      <p>{props.item.Description}</p>
      <p>Category: {props.item.Type}</p>
    </div>
  );
};
