import departmentprofile from "../../assets/images/departmentprofile.jpg";
import departmentvalues from "../../assets/images/departmentvalues.png";
import departmentgovernance from "../../assets/images/departmentgovernance.png";
export default function AboutDepartment(props) {
  return (
    <div className="about">
      <div className="container">
        <div className="divequal">
          <h1>About Us</h1>
          <h2>County Department of Agriculture, Livestock, Veterinary & Fisheries</h2>
          <img src={departmentprofile} alt="" />
          <p>
            The Wezesha Kirinyaga economic empowerment program, 473 Community interest groups have been
            supported to undertake agricultural projects in poultry, avocado, tomato and dairy value
            chains among other projects. This has an impact on about 12,000 households in the county.
            Thirty two community groups have undertaken poultry production projects that have increased
            egg production by one million eggs per month in the county. The project earns each of the
            participating 1,000 households an extra income of Ksh. 1,000 daily.
          </p>
          <p>
            The additional income goes a long way in raising the living standards for the county residents.
            The County Government has distributed over 300,000 Hass avocado seedlings to farmers and is 
            constructing avocado aggregation centers and an avocado factory in support of the avocado 
            farmers. With the completion of Thiba dam, the annual rice production is expected to double 
            from the current 80,000 tons to 160,0000 tons
          </p>
        </div>
        <br />
        <div className="divequal">
          <img src={departmentvalues} alt="" />
          <p>
            254,000 Fish fingerlings, free fish pond liners, and six months of free fish feeds have been 
            issued to fish farmers to enhance aquaculture farming. An exclusive fish market has been 
            established, Kiaga fish aggregation center shall be a reliable facility for domestic fish 
            bulking, preliminary processing, and a one-stop fish shop. Artificial Insemination program has 
            been established to improve the county’s cattle breeds and to increase milk production. 
            As of now, over 50,000 cows are served with semen for high milk-producing cattle breeds. A demo 
            farm already in place at Kamweti and Kangai set to teach farmers about best agricultural 
            practices.
          </p>
        </div>
        <div className="divequal">
          <img src={departmentgovernance} alt="" />
          <p>
            
          </p>
        </div>
      </div>
    </div>
  );
}
