import React from "react";
import "../Styles/aboutpage.scss";
import Header from "./../components/Utils/header";
import Footer from "../components/Utils/footer";
import { useEffect, useState } from "react";
import Hero from "../components/Home/Hero";
import Data from "../components/Home/Data";
import Services from "../components/Home/Services";
import Partners from "../components/Home/Partners";
import Benefits from "../components/Home/Benefits";
import AboutDepartment from "../components/About/AboutDepartment";
export default function About() {
  return (
    <div className="aboutpage">
      <div className="headings">
        <Header />
      </div>
      <AboutDepartment />
      <Footer />
    </div>
  );
}
