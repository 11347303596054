import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import HeaderModal from "./modal_header";

function NavLink(props) {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onMouseLeave={() => {
        if (props.data?.length > 0) {
          setIsOpen(false);
        }
      }}
      className="outer"
    >
      <div
        className="navlink"
        onMouseOver={() => {
          if (props.data?.length > 0) {
            setIsOpen(true);
          }
        }}
      >
        {props.active === props.txt ? (
          <a
            data-aos="zoom-in"
            className="active"
            href={props.data?.length > 0 ? "#" : props.url}
          >
            {props.txt}
            {props.data?.length > 0 && <i className="fa ">&#xf107;</i>}
          </a>
        ) : (
            <a data-aos="zoom-in" href={props.data?.length > 0 ? "#" : props.url}>
            {props.txt}
            {props.data?.length > 0 && <i className="fa">&#xf107;</i>}
          </a>
        )}
      </div>
      {isOpen && <DropDown data={props.data} />}
    </div>
  );
}

function DropDown(props) {
  return (
    <div className="dropdown-content">
      {props.data && props.data.map((item, index) => {
        return <p onClick = {item.url.includes('https://') ? () => {window.open(item.url)}
          : () => {window.location.href = item.url}} key={index}>
              {item.name}
            </p>
      })}
    </div>
  );
}

export default function Header(props) {
  const [clicked, setClicked] = useState(false);
  const hd = useRef();

  const links = { 
    portals: [ 
      { name: "Staff Portal", url: "https://admin-kirinyaga.dat.co.ke/"},
      { name: "Farmers Portal", url: "https://farmers-kirinyaga.dat.co.ke/" },
      { name: "Agricultural Resource Center", url: "https://arc-kirinyaga.dat.co.ke/" },
    ],
    departments: [
      { name: "Agriculture", url: "/departments/agriculture" },
      { name: "Livestock", url: "/departments/livestock" },
      { name: "Veterinary Services", url: "/departments/veterinary" },
      { name: "Fisheries", url: "/departments/fisheries" },
      { name: "KATC", url: "/departments/katc" },
    ],
    projects: [
      { name: "ASDSP", url: "/projects/asdsp" },
      { name: "ABDP", url: "/projects/abdp" },
      { name: "NARIGP", url: "/projects/narigp" },
      { name: "NAVCDP", url: "/projects/navcdp" },
    ],
    stakeholders: [
      { name: "All", url: "/stakeholders" },
      { name: "Development Partners", url: "/stakeholders/devpartners" },
      { name: "State Actors", url: "/stakeholders/state" },
      { name: "Non-state Actors", url: "/stakeholders/nonstate" },
      { name: "Service Providers", url: "/stakeholders/services" },
    ],
    news: [
      { name: "News", url: "/news" },
      { name: "Testimonials", url: "/testimonials" },
    ],
                }

  useEffect(() => {
    window.addEventListener("scroll", changeCss, false);
  }, []);

  function changeCss() {
    if (this.scrollY > 0) {
      hd.current.style.position = "fixed";
    } else {
      hd.current.style.position = "relative";
    }
  }

  return (
    <>
      <div ref={hd} className="header">
        <div className="contacts">
          <div className="container">
            <div>
              <img
                onClick={() => {
                  window.location.href = "/";
                }}
                src={logo}
                alt=""
              />
              <h1>Kiri AMIS</h1>
            </div>
            <a href="tel:+254700000000">
              <i className="fa ">&#xf095;</i> 0800724580
            </a>
            <a href="mailto:info@kiriamis.co.ke">
              <i className="fa ">&#xf1fa;</i> info@kiriamis.co.ke
            </a>
          </div>
        </div>
        <div className="hcontainer">
          <div className="wrapper">
            <NavLink txt="Home" url="/" active={props.active} />
            <NavLink txt="About Us" url="/about" active={props.active} />
            <NavLink
              txt="Portals"
              url="/portal"
              active={props.active}
              data={links.portals}
            />
            <NavLink
              txt="Departments"
              url="/departments"
              active={props.active}
              data={links.departments}
            />
            <NavLink
              txt="Projects"
              url="/projects"
              active={props.active}
              data={links.projects}
            />
            <NavLink
              txt="Stakeholders"
              url="/stakeholders"
              active={props.active}
              // data={links.stakeholders}
            />
            <NavLink
              txt="News"
              url="/news"
              active={props.active}
              data={links.news}
            />
            {/* <NavLink
              txt="Testimonials"
              url="/testimonials"
              active={props.active}
            /> */}
            <NavLink txt="Contact Us" url="/contact" active={props.active} />
          </div>
          <div className="mob">
            <i
              onClick={() => {
                setClicked(true);
              }}
              className="fa"
            >
              &#xf0c9;
            </i>
          </div>
        </div>
      </div>
      {clicked && <HeaderModal logo={logo} setClicked={setClicked} links={links}/>}
    </>
  );
}
