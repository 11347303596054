import departmentprofile from "../../assets/images/departmentprofile.jpg";
import departmentvalues from "../../assets/images/departmentvalues.png";
import departmentgovernance from "../../assets/images/departmentgovernance.png";
import SlidingImages from "./SlidingImages";

import bg1 from "../../assets/images/bg1.jpg";
import bg2 from "../../assets/images/bg2.jpg";
import bg3 from "../../assets/images/bg3.jpg";
import bg4 from "../../assets/images/bg4.jpg";
import bg5 from "../../assets/images/bg5.jpg";
import bg6 from "../../assets/images/bg6.jpg";
import bg7 from "../../assets/images/bg7.jpg";

export default function KATC(props) {

  const content = [
    {
      title: <p>Subsidized fertilizer to reduce cost of production.</p>,
      img: bg1,
    },
    {
      title: <p>Milk coolants that improve milk preservation.</p>,
      img: bg2,
    },
    {
      title: <p>Thiba Dam to sustain agriculture off the rainy seasons.</p>,
      img: bg3,
    },
    {
      title: <p>Tomato Propagation. Number 1. county producer of tomatoes in Kenya.</p>,
      img: bg4,
    },
    {
      title: <p>Bee Hive Management for Quality Honey.</p>,
      img: bg5,
    },
    {
      title: <p>Fish Harvesting from Sustainable Fish Ponds.</p>,
      img: bg6,
    },
    {
      title: <p>Brooding Day Old Chicks</p>,
      img: bg7,
    },
  ];

  return (
    <div className="department">
      <div className="container">
        <h1>Kirinyaga Agricultural Training Center</h1>
        <div className="div2equal">
          <SlidingImages content={content} />
          <div className="text">
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}
