import departmentprofile from "../../assets/images/departmentprofile.jpg";
import departmentvalues from "../../assets/images/departmentvalues.png";
import departmentgovernance from "../../assets/images/departmentgovernance.png";
import SlidingImages from "./SlidingImages";

import bg1 from "../../assets/images/bg1.jpg";
import bg2 from "../../assets/images/bg2.jpg";
import bg3 from "../../assets/images/bg3.jpg";
import bg4 from "../../assets/images/bg4.jpg";
import bg5 from "../../assets/images/bg5.jpg";
import bg6 from "../../assets/images/bg6.jpg";
import bg7 from "../../assets/images/bg7.jpg";

export default function Fisheries(props) {

  const content = [
    {
      title: <p>Subsidized fertilizer to reduce cost of production.</p>,
      img: bg1,
    },
    {
      title: <p>Milk coolants that improve milk preservation.</p>,
      img: bg2,
    },
    {
      title: <p>Thiba Dam to sustain agriculture off the rainy seasons.</p>,
      img: bg3,
    },
    {
      title: <p>Tomato Propagation. Number 1. county producer of tomatoes in Kenya.</p>,
      img: bg4,
    },
    {
      title: <p>Bee Hive Management for Quality Honey.</p>,
      img: bg5,
    },
    {
      title: <p>Fish Harvesting from Sustainable Fish Ponds.</p>,
      img: bg6,
    },
    {
      title: <p>Brooding Day Old Chicks</p>,
      img: bg7,
    },
  ];

  return (
    <div className="department">
      <div className="container">
        <h1>Fisheries Development Section</h1>
        <div className="div2equal">
          <SlidingImages content={content} />
          <div className="text">
            <p>
              The mandate of the Fisheries Development Section is the exploration, exploitation, utilization, 
              management, development, and conservation of fisheries resources, aquaculture development, 
              inland freshwater blue economy, and planning.
              Key sub-sections include:
            </p>
            <ol>
              <li>Aquaculture development, </li>
              <li>Fish quality assurance, </li>
              <li>Agribusiness development, </li>
              <li>Training & Extension. </li>
            </ol>
            <p>
              Core functions of include:
            </p>
            <ol>
              <li>Formulation, implementation, monitoring and review of fisheries policy, </li>
              <li>Promotion and development of fish farming, </li>
              <li>Regulation of quality of Fish inputs, </li>
              <li>Management of fisheries information, </li>
              <li>Facilitate development & rehabilitation of Fish marketing infrastructure. </li>
            </ol>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}
