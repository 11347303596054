import "../../Styles/_header.scss";
import React, { useState } from "react";
import logo2 from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";

function NavLink(props) {
  const [clicked, setClicked] = useState(null);
  const location = useLocation();
  let link;
  if (props.url === location.pathname) {
    link = (
      <>
        <h4
          className="underline"
          onClick={() => {
            if (props.data) { 
              if (clicked == null) {
                setClicked(props.txt);
              }
              else {
                setClicked(null);
              }
            } 
            else { 
              window.location.href = props.url;
            }
          }}
        >
          {props.txt === clicked ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>} {props.txt}
        </h4>
        {props.txt === clicked && <DropDown data={props.data} />}
      </>
    );
  } else {
    link = (
      <>
        <h4
          onClick={() => {
            if (props.data) {
              if (clicked == null) {
                setClicked(props.txt);
              }
              else {
                setClicked(null);
              }
            }
            else {
              window.location.href = props.url;
            }
          }}
        >
          {props.txt === clicked ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>} {props.txt}
        </h4>
        {props.txt === clicked && <DropDown data={props.data}/>}
      </>
    );
  }
  return link;
}

export default function ModalHeader(props) {
  return (
    <div className="modalheader">
      <div className="container">
        <div className="itemsLogo">
          <div
            onClick={() => {
              window.location.href = "/";
            }}
            className="logo"
          >
            <img src={logo2} alt="Oakar Services Ltd Logo" />
            <h2>KiriAMIS</h2>
          </div>
        </div>

        <h3>Menus</h3>
        <div className="nav">
          <NavLink txt="Home" url="/" active={props.active} />
          <NavLink txt="About Us" url="/about" active={props.active} />
          <NavLink txt="Portals" url="/portal" active={props.active} data={props.links.portals}/>
          <NavLink txt="Departments" url="/departments" active={props.active} data={props.links.departments} />
          <NavLink txt="Projects" url="/projects" active={props.active} data={props.links.projects} />
          <NavLink
            txt="Stakeholders"
            url="/stakeholders"
            active={props.active}
          />
          <NavLink txt="News" url="/news" active={props.active} data={props.links.news} />
          <NavLink txt="Contact Us" url="/contact" active={props.active} />
        </div>
        <i
          onClick={() => {
            props.setClicked(false);
          }}
          className="fa fa-times"
        ></i>
      </div>
    </div>
  );
}

function DropDown(props) {
  return (
    <div className="dropdown-content">
      {props.data?.map ((item, index) => {
        return (<p key={index} onClick={() => window.open(item.url)}>
          <i className="fa fa-link"></i> {item.name}
        </p>)
      })}
    </div>
  );
}
