import React, { useEffect, useState } from "react";

export default function SlidingImages(props) {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (currentIndex + 1) % props.content.length;
      setCurrentIndex(nextIndex);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  return (
    <div className="slideshow-container">
      {props.content.map((item, index) => (
        <MySlider
          key={index}
          item={item}
          index={index}
          currentIndex={currentIndex}
        />
      ))}
    </div>
  );
}

const MySlider = (props) => {
  const [animationActive, setAnimationActive] = useState(false);

  useEffect(() => {
    setAnimationActive(false);
    setTimeout(() => {
      setAnimationActive(true);
    }, 2000);
  }, [props.currentIndex]);

  return (
    <div
      className={`slideshow-image ${
        props.index === props.currentIndex ? "active" : ""
      }`}
    >
      <div className="change">
        <img src={props.item.img} />
        <p>{props.item.title}</p>
      </div>
    </div>
  );
};
